import React from "react";
import JyvButton from "../general/jyvButton";
import Grid from "@mui/material/Grid";

import EventCard from "../eventCard";
import { NO_EVENTS_HELPER_TEXT } from "../../tools/appText";

// TODO: update everything below
export default function ProfileContent(props) {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      style={{ marginTop: 20 }}
    >
      {props.events?.length > 0 ? (
        <Grid container>
          {props.events?.map((event) => (
            <EventCard event={event} handleAttendanceUpdate={props.handleAttendanceUpdate} userId={props.userId}/>
          ))}
        </Grid>
      ) : (
        <>
          {/* TODO: update the text in app text */}
          <div style={{ marginBottom: 20 }}>{NO_EVENTS_HELPER_TEXT}</div>
        </>
      )}
    </Grid>
  );
}
