import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "gatsby";
import _ from "underscore";
import * as API from "../utils/api";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SEO from "../components/seo";

import "@fontsource/poppins";
import JyvIcon from "../images/jyv_logo.png";
import "../components/app.css";
import NavigationTabs from "../components/tabs/tabs";
import ProfileContent from "../components/profile/profileContent";
import ProfileEvents from "../components/profile/profileEvents";
import Avatar from "@mui/material/Avatar";
import JyvButton from "../components/general/jyvButton";
import JyvModal from "../components/general/jyvModal";
import JyvTextField from "../components/general/jyvTextField";
import Layout from "../components/layout";
import Loading from "../components/general/loading";

// CONSTANTS
const TABS = [
  { label: "Content", value: "content" },
  { label: "Events", value: "events" },
];

const ProfilePage = (props) => {
  const id = queryString.parse(props.location?.search).id;
  const userId = useSelector((state) => state?.auth?.userId, _.isEqual);
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn, _.isEqual);
  const loggedInUser = useSelector((state) => state?.user?.user, _.isEqual);
  const [email, setEmail] = useState("");
  const [pw, setPW] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [tab, setTab] = useState("content");
  const [open, setIsOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(undefined);
  const [content, setContent] = useState(undefined);
  const [events, setEvents] = useState(undefined);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [isSubscribed, setIsSubscribed] = useState(false);
  const dispatch = useDispatch();

  const refetchProfileData = async () => {
    try {
      const fetchedUser = await dispatch.profile.getUser(id);
      const fetchedContent = await dispatch.profile.getProfileContent(
        id,
        userId
      );
      const fetchedEvents = await dispatch.profile.getProfileEvents(id, userId);
      setUser(fetchedUser);
      setContent(fetchedContent);
      setEvents(fetchedEvents);
    } catch (e) {
      let message = "There was an error";
      if (!!e.message && e.message?.length > 0) {
        message = message + e.message;
      }
      setSnackbarMessage(message);
      setSnackOpen(true);
      return;
    }
  };

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const fetchedUser = await dispatch.profile.getUser(id);
        const fetchedContent = await dispatch.profile.getProfileContent(
          id,
          userId
        );
        const fetchedEvents = await dispatch.profile.getProfileEvents(
          id,
          userId
        );
        setUser(fetchedUser);
        const subscribed = loggedInUser?.subscription_ids?.includes(
          fetchedUser?.publicSubId
        );
        setIsSubscribed(subscribed);
        setContent(fetchedContent);
        setEvents(fetchedEvents);
      } catch (e) {
        let message = "There was an error";
        if (!!e.message && e.message?.length > 0) {
          message = message + e.message;
        }
        setSnackbarMessage(message);
        setSnackOpen(true);
      }
      setIsLoading(false);
    };
    if (user === undefined || events === undefined || content === undefined) {
      fetchProfileData();
    }

    return async () => {
      //  TODO: If i want to clear any data on unmount
    };
  }, [user, events, content, loggedInUser]);

  // TODO: delete test user data
  //   const contents = [
  //     { name: "Moira Turner", imageUrl: TestImage1, title: "Test Title", profileImage: TestProfileImage },
  //     { name: "Moira Turner", imageUrl: TestImage2, title: "Test Title", profileImage: TestProfileImage },
  //   ];
  // const user = {name: 'Moira Turner', profileImage: TestProfileImage};
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleSubscribe = async (subscriberId) => {
    // TODO: if not a logged in user, open modal to create a user
    if (!isLoading) setIsLoading(true);
    try {
      const payload = { user_id: user.user_id, subscriber_id: subscriberId };
      await API.subscribeUser(payload);
      refetchProfileData();
      setIsSubscribed(true);
    } catch (e) {
      let message = "There was an error";
      if (!!e.message && e.message?.length > 0) {
        message = message + e.message;
      }
      setSnackbarMessage(message);
      setSnackOpen(true);
    }
    setIsLoading(false);
  };
  const handleClose = () => {
    setSnackOpen(false);
    setSnackbarMessage("");
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleUnsubscribe = async () => {
    const payload = { user_id: user.user_id, subscriber_id: userId };
    try {
      await API.unsubscribeUser(payload);
      refetchProfileData();
    } catch (e) {
      let message = "There was an error";
      if (!!e.message && e.message?.length > 0) {
        message = message + e.message;
      }
      setSnackbarMessage(message);
      setSnackOpen(true);
      return;
    }
  };

  const handleCreateUser = async () => {
    setIsLoading(true);
    try {
      const payload = {
        email: email,
        pw: pw,
        userData: { phone: phone, name: name, email: email },
      };
      const newUserId = await dispatch.auth.signUp(payload);
      await handleSubscribe(newUserId);
    } catch (e) {
      let message = "There was an error";
      if (!!e.message && e.message?.length > 0) {
        message = message + e.message;
      }
      setSnackbarMessage(message);
      setSnackOpen(true);
    }
    setIsLoading(false);
    setIsOpen(false);
  };

  const handleAttendanceUpdate = async (status, id) => {
    try {
      const payload = { user_id: userId, event_id: id, status: status };
      await API.updateAttendance(payload);
    } catch (e) {
      let message = "There was an error";
      if (!!e.message && e.message?.length > 0) {
        message = message + e.message;
      }
      setSnackbarMessage(message);
      setSnackOpen(true);
      return;
    }
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePWChange = (e) => {
    setPW(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  return (
    <Layout bypassLogin={true}>
      {/* TODO: make this open on event-details */}
      <JyvModal open={open} onClose={() => setIsOpen(false)}>
        {" "}
        <Grid item style={{ marginTop: 20 }}>
          <Grid container style={{ marginBottom: 20 }} alignItems="center">
            Create an Account
          </Grid>
          <Grid item style={{ width: "100%" }}>
            <JyvTextField
              placeholder="Name"
              value={name}
              variant="filled"
              onChange={handleNameChange}
            />
          </Grid>
          <Grid item style={{ width: "100%", marginTop: 10 }}>
            <JyvTextField
              placeholder="Email"
              variant="filled"
              value={email}
              onChange={handleEmailChange}
            />
          </Grid>
          <Grid style={{ width: "100%", marginTop: 10 }}>
            <JyvTextField
              placeholder="Phone"
              value={phone}
              variant="filled"
              onChange={handlePhoneChange}
            />
          </Grid>
          <Grid style={{ width: "100%", marginTop: 10 }}>
            <JyvTextField
              value={pw}
              placeholder="Password"
              variant="filled"
              onChange={handlePWChange}
              type="password"
            />
          </Grid>
        </Grid>
        <Grid item style={{ marginTop: 20 }}>
          {isLoading ? (
            <Grid container direction="column" justifyContent="center" alignContent="center">
              <Grid item>
                <Loading />
              </Grid>
              <Grid item style={{marginTop: 10}}>This may take a few seconds</Grid>
            </Grid>
          ) : (
            <JyvButton
              text="complete"
              color="confirm"
              variant="contained"
              onClick={handleCreateUser}
            />
          )}
        </Grid>
      </JyvModal>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ padding: 20, width: "100%" }}
      >
        {" "}
        {isLoggedIn && (
          <Grid
            item
            style={{ width: "100%", color: "white", padding: 5 }}
            onClick={() => navigate("/")}
          >
            Back
          </Grid>
        )}
        <Grid item style={{ marginBottom: 5 }}>
          {" "}
          <Avatar
            alt={`${user?.name}-profile-image`}
            src={JyvIcon}
            sx={{ width: 125, height: 125 }}
          />
        </Grid>
        <Grid style={{ fontSize: 25 }}>{user?.name}</Grid>
        <Grid
          style={{ fontSize: 12, marginBottom: 15, color: "#5D91B7" }}
          onClick={() => {
            navigator.clipboard.writeText(
              `https://jyvapi.web.app/profile/?id=${user.user_id}`
            );
            setSnackbarMessage("Shareable Link Copied");
            setSnackOpen(true);
          }}
        >
          Share profile
        </Grid>
        {/* TODO: add a loading mask when subscribing */}
        <Grid item>
          {!isSubscribed ? (
            <JyvButton
              text="Subscribe"
              variant="contained"
              color="primary"
              onClick={
                !isLoggedIn
                  ? () => setIsOpen(true)
                  : () => handleSubscribe(userId)
              }
            />
          ) : (
            <JyvButton
              text="Unsubscribe"
              variant="contained"
              color="primary"
              onClick={handleUnsubscribe}
            />
          )}
        </Grid>
        <Grid item>
          {" "}
          <NavigationTabs handleChange={handleChange} value={tab} tabs={TABS} />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          {tab === "content" && (
            <ProfileContent content={content} userId={userId} />
          )}
        </Grid>
        {tab === "events" && (
          <ProfileEvents
            events={events}
            handleAttendanceUpdate={handleAttendanceUpdate}
            userId={userId}
          />
        )}
      </Grid>
      <Snackbar
        open={snackOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        message={snackbarMessage}
        action={action}
        key={"top" + "center"}
      />
    </Layout>
  );
};

export default ProfilePage;

export const Head = () => (
  <SEO />
)
