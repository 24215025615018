import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: 400,
  bgcolor: '#808287',
  color: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function JyvModal({ children, ...modalProps }) {
  return (
      <Modal
      {...modalProps}
      style={{width: '95%', maxHeight: '60vh'}}
      >
        <Box sx={style}>
         {children}
        </Box>
      </Modal>
  );
}