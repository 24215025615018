import React from "react";
import Grid from "@mui/material/Grid";
import _ from "underscore";
import Avatar from "@mui/material/Avatar";
import { navigate } from "gatsby";
import * as API from "../utils/api";

import Menu from "../components/general/menu";

export default function ContentCard(props) {
  const url = props.content.file_url;
  const isOwner = props.content.creator_id === props.userId;

  const handleDelete = async () => {
    try {
      const payload = { type: "content", id: props.content.id };
      await API.deleteContent(payload);
      props.reload();
    } catch (e) {
      console.log(e);
    }
  };

  const editingOptions = [
    {
      label: "Edit",
      handleClick: () => navigate("/create", { state: { content: props.content } }),
      hasIcon: false,
    },
    {
      label: "Delete",
      handleClick: handleDelete,
      hasIcon: false,
    },
  ];

  return (
    <Grid
      container
      direction="column"
      className="content-card-container"
      style={{ width: "100%", marginBottom: 15 }}
    >
      <Grid
        item
        style={{ width: "100%", height: 350 }}
        onClick={() => navigate("/view", { state: { url } })}
      >
        <video
          style={{
            width: "100%",
            height: "100%",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            objectFit: "cover",
          }}
          src={props.content?.file_url}
          className="content-card-cover-image"
          muted={true}
          autoPlay
          loop
          playsInline
        />
      </Grid>
      <Grid
        item
        style={{
          backgroundColor: "black",
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
        }}
      >
        <Grid
          container
          direction="row"
          className="content-card-details"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Grid
              container
              direction="row"
              alignItems="center"
              onClick={() =>
                navigate(`/profile/?id=${props.content?.creator_id}`)
              }
            >
              <Grid item style={{ margin: 10, marginRight: 10 }}>
                <Avatar
                  alt={`${props.content.name}-profile-image`}
                  src={props.content.profileImage}
                  sx={{ width: 40, height: 40 }}
                />
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  <Grid item style={{ fontSize: 12 }}>
                    {props.content.name}
                  </Grid>
                  <Grid item style={{ fontSize: 12 }}>
                    {props.content.creator_name}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {isOwner && (
            <Grid item style={{ marginLeft: -7 }}>
              <Menu options={editingOptions} isLongIcon={true} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
