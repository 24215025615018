import React from "react";
import Grid from "@mui/material/Grid";

import ContentCard from "../contentCard";
import { NO_CONTENT_HELPER_TEXT } from "../../tools/appText";
// TODO: update everything below
export default function ProfileContent(props) {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      style={{ marginTop: 20, width: '100%' }}
    >
      {props.content?.length > 0 ? (
        <Grid container>
          {props.content.map((content) => (
            <ContentCard content={content} userId={props.userId} />
          ))}
        </Grid>
      ) : (
        <>
          {/* TODO: update the text in app text */}
          <div style={{ marginBottom: 20 }}>{NO_CONTENT_HELPER_TEXT}</div>
        </>
      )}
    </Grid>
  );
}
